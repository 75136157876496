/* eslint-disable max-len */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterIcon from '../../assets/icons/FilterIcon';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import CheckBox from 'components/Input/CheckBox';

const Dropdown = ({
  type,
  placeholder,
  variant,
  children,
  disabled = false,
  options,
  icon,
  filterIcon,
  innerVariant,
  value,
  onChange,
  optionContainerVariant,
  inputLabel,
  inputPlaceholder,
  inputId,
  showIcon = false,
  inputName,
  labelVariant,
  inputStyles,
  providers,
}) => {
  //local State
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropDown, setDropDown] = useState('');
  const [iconType, setIconType] = useState('p-3');
  const buttonRef = useRef();
  //Handler Functions
  const toggleDropdown = useCallback(() => {
    setShowDropdown((prevState) => !prevState);
  }, []);
  const handleSelect = (item) => {
    //Send selected to form
    onChange(item);
    setShowDropdown(false);
  };
  useOnClickOutside([buttonRef], () => setShowDropdown(false), 200);


  function dropDownSwitch() {
    switch (type) {
      case 'icon':
        return setDropDown(
          <button className="">
            <FilterIcon />
          </button>,
        );
      case 'filterIcon':
        return setDropDown(<button className="">{filterIcon}</button>);
      case 'filter':
        return setDropDown(
          <div className={innerVariant}>
            <p className={`${labelVariant || 'text-black-transparent'}`}>{value?.label ?? placeholder}</p> <p>{icon}</p>
          </div>,
        );
      case 'input':
        return setDropDown(
          <div className="font-dmsans_r">
            <div>
              <p className={`${variant} text-grey mb-2 block text-14`}>{inputLabel}</p>
              <div
                data-testid={`test-${inputId}`}
                name={inputName}
                className={`${inputStyles} rounded-lg border-deep-grey bg-green_light px-16 py-12 md:text-14 text-14 capitalize w-full border outline-0  font-dmsans_r h-11`}
              >
                {value?.label ?? <span className="text-grey">{placeholder}</span>}
              </div>
            </div>

            <span className="absolute inset-y-0 right-0 top-[46px] flex items-center ">
              {showDropdown ? (
                <IoIosArrowUp color={'#435A70'} className="text-20 absolute right-3 top-[60%] -mt-1 cursor-pointer" />
              ) : (
                <IoIosArrowDown color={'#435A70'} className="text-20 absolute right-3 top-[60%] -mt-1 cursor-pointer" />
              )}
            </span>
          </div>,
        );
    }
  }
  function IconSwitch() {
    switch (type) {
      case 'icon':
        return setIconType('p-3');
      case 'input':
        return setIconType('p-0');
      case 'filterIcon':
        return setIconType('p-0');
    }
  }
  useEffect(() => {
    IconSwitch();
  }, []);
  useEffect(() => {
    dropDownSwitch();
  }, [value]);

  return (
    <>
      {disabled ? (
        <div>
          <p className={`${variant} text-grey mb-2 block text-14`}>{inputLabel}</p>
          <div
            data-testid={`test-${inputId}`}
            name={inputName}
            className={`${inputStyles}rounded-lg border-deep-grey bg-green_light px-16 py-12 md:text-14 text-14 capitalize w-full border outline-0  font-dmsans_r h-[52px]`}
          >
            {value?.label ?? <span className="text-grey">{inputPlaceholder}</span>}
          </div>
        </div>
      ) : (
        <div ref={buttonRef} className="relative">
          <div
            onClick={toggleDropdown}
            className={`${variant} h-11 text-16 font-dmsans_b rounded cursor-pointer ${iconType}  `}
            data-testid={`${type}-dropdown-input`}
          >
            {dropDown}
          </div>
          {showDropdown && (
            <div
              className={`absolute border  border-light-smoke rounded bg-white overflow-y-scroll ${
                optionContainerVariant || 'w-full'
              } right-0 mt-0.5 z-10`}
              data-testid="dropdown-modal"
            >
              <div className={providers ? 'flex flex-row' : 'block'}>
                <div className={providers ? 'flex-initial w-48' : 'w-full'}>
                  {children ||
                    options?.map((item, index) => (
                      <button
                        type="button"
                        key={item.id || index}
                        className={`${
                          item.styles
                        } h-10 hover:bg-smoke cursor-pointer  font-dmsans_r border-b border-light-gray w-full text-left ${
                          showIcon ? 'flex items-center pl-2 space-x-1' : ' block py-3 px-3.5'
                        }`}
                        onClick={() => handleSelect(item)}
                        data-testid={item.label}
                      >
                        {showIcon && <CheckBox variant="ml-0 mr-0.5" />}
                        <p className=" text-black-transparent text-14">{item.label}</p>
                      </button>
                    ))}
                </div>
                {providers && (
                  <div className="flex-initial w-48">
                    {providers?.map((item, index) => (
                      <div
                        key={item.id || index}
                        className={`${
                          item.styles
                        } h-10 hover:bg-smoke cursor-pointer  font-dmsans_r border-b border-light-gray ${
                          showIcon ? 'flex items-center pl-2 space-x-1' : 'py-3 px-3.5'
                        }`}
                        onClick={() => handleSelect(item)}
                        data-testid={item.label}
                      >
                        {showIcon && <CheckBox variant="ml-0 mr-0.5" />}
                        <p className=" text-black-transparent text-14">{item.label}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dropdown;
Dropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
  children: PropTypes.any,
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  filterIcon: PropTypes.any,
  options: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.object,
  inputStyles: PropTypes.string,
  inputLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputId: PropTypes.string,
  labelVariant: PropTypes.string,
  showIcon: PropTypes.bool,
  inputName: PropTypes.string,
  variant: PropTypes.string,
  optionContainerVariant: PropTypes.string,
  innerVariant: PropTypes.string,
  providers: PropTypes.array,
};
