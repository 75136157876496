import React, { useEffect, useState } from 'react';
import useAdmin from 'hooks/fetch/useAdmin';
import useFormatDate from 'hooks/useFormatDate';
import NewTable from 'components/Table/NewTable';
import { useTransferState } from 'store/modules/transfers';
import { useDashboardState } from 'store/modules/dashboard';
import DashboardTopCard from 'components/Card/DashboardTopCard';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import DefaultDatePicker from 'components/Input/DefaultDatePicker';

const DashboardHome = () => {
  //Hooks
  const { getToday } = useFormatDate();
  const { handleDashboardAnalytics, handleProviderBalancesUserAnalytics } = useAdmin();
  //Local State
  const [fromDate, setFromDate] = useState(getToday());
  const [toDate, setToDate] = useState(getToday());
  //Redux
  const { dashboardAnalytics, loading, loadingProvider, providerBalancesUserAnalytics } = useDashboardState();
  const { customersTransactionHistory, loading: loadingTransactions } = useTransferState();
  const { handleGetCustomerTransactionHistory } = useAdmin();

  //Effect
  useEffect(() => {
    handleDashboardAnalytics({ fromDate, toDate });
  }, [toDate, fromDate]);
  useEffect(() => {
    handleProviderBalancesUserAnalytics();
    handleGetCustomerTransactionHistory({ page: 1, perPage: 10 });
  }, []);
  useEffect(() => {
    document.title = 'Dashboard - Pouchers Admin';
  }, []);
  return (
    <DashboardLayout pageHeader="Dashboard" variant="mb-0" hasFilter={false}>
      <div className="flex flex-col gap-10">
        <div className="flex justify-end items-center space-x-2">
          <p className="font-dmsans_r ">Filter:</p>
          <div className="flex md:space-x-2 md:space-y-0 xxs:space-y-2 items-center flex-wrap">
            <DefaultDatePicker
              defaultValue={getToday()}
              onHandleDateChange={(date) => {
                setFromDate(date);
              }}
              datepickerClass="top-12 right-0"
              selectedDate={new Date(fromDate)}
            />
            <p className="text-grey-700 mx-2">-</p>
            <DefaultDatePicker
              onHandleDateChange={(date) => {
                setToDate(date);
              }}
              selectedDate={new Date(toDate)}
              defaultValue={getToday()}
            />
          </div>
        </div>
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <DashboardTopCard amount data={dashboardAnalytics?.total_revenue} label="Total revenue" loading={loading} />
          <DashboardTopCard
            data={dashboardAnalytics?.total_transactions}
            label="Total Transactions"
            loading={loading}
          />
          <DashboardTopCard
            data={dashboardAnalytics?.successful_total}
            label="Successful Transactions"
            loading={loading}
          />
          <DashboardTopCard
            data={dashboardAnalytics?.total_transaction_amount}
            label="Total Transactions Amount"
            loading={loading}
          />
          <DashboardTopCard
            data={dashboardAnalytics?.total_transaction_fee}
            label="Total Transactions Fee"
            loading={loading}
          />
          <DashboardTopCard
            data={dashboardAnalytics?.total_processed_volume}
            label="Total Processed Volume"
            loading={loading}
          />
          <DashboardTopCard data={dashboardAnalytics?.failed_total} label="Failed Transactions" loading={loading} />
          <DashboardTopCard data={dashboardAnalytics?.pending_total} label="Pending Transactions" loading={loading} />
          <DashboardTopCard data={dashboardAnalytics?.airtime_transaction_count} label="Airtime" loading={loading} />
          <DashboardTopCard data={dashboardAnalytics?.data_transaction_count} label="Data" loading={loading} />
          <DashboardTopCard data={dashboardAnalytics?.other_bill_transaction_count} label="Bills" loading={loading} />
          <DashboardTopCard data={dashboardAnalytics?.transfer_transaction_count} label="Transfers" loading={loading} />
          <DashboardTopCard
            data={dashboardAnalytics?.voucher_purchase_count}
            label="Vouchers Purchased"
            loading={loading}
          />
          <DashboardTopCard
            data={dashboardAnalytics?.voucher_redeem_count}
            label="Vouchers Redeemed"
            loading={loading}
          />
          <DashboardTopCard data={dashboardAnalytics?.card_created_count} label="Cards Created" loading={loading} />
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-18 font-dmsans_r">Provider Balances</p>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.sochitel_balance || 0}
              label="Sochitel"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.anchor_balance || 0}
              label="Anchor"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.wallet_balance_sum || 0}
              label="Wallet Balance"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.dojah_balance || 0}
              label="Dojah"
              loading={loadingProvider}
            />
            <DashboardTopCard
              data={providerBalancesUserAnalytics?.sendpulse_balance || 0}
              label="Send Pulse"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.termii_balance || 0}
              label="Termii"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.quick_teller || 0}
              label="Quick Teller"
              loading={loadingProvider}
            />
            <DashboardTopCard
              amount
              data={providerBalancesUserAnalytics?.vas2net_balance || 0}
              label="Vas 2 Net"
              loading={loadingProvider}
            />
            <DashboardTopCard amount data={providerBalancesUserAnalytics?.sudo_balance || 0} label="Sudo Balance" loading={loading} />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-18 font-dmsans_r">Users</p>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <DashboardTopCard
              percent={providerBalancesUserAnalytics?.daily_active_user_change_percentage}
              data={providerBalancesUserAnalytics?.active_users}
              label="Active Users"
              loading={loadingProvider}
            />
            <DashboardTopCard
              percent={providerBalancesUserAnalytics?.daily_inactive_user_change_percentage}
              data={providerBalancesUserAnalytics?.inactive_users}
              label="Inactive Users"
              loading={loadingProvider}
            />
            <DashboardTopCard
              percent={providerBalancesUserAnalytics?.daily_verified_user_change_percentage}
              data={providerBalancesUserAnalytics?.verified_users}
              label="Verified Users"
              loading={loadingProvider}
            />
          </div>
          <div className="w-full mt-3 grid grid-cols-1 md:grid-cols-2 gap-6">
            <DashboardTopCard
              percent={providerBalancesUserAnalytics?.daily_users_created_change_percentage}
              data={providerBalancesUserAnalytics?.users_created_today}
              label="New Users"
              loading={loadingProvider}
            />
            <DashboardTopCard
              percent={providerBalancesUserAnalytics?.daily_non_verified_user_change_percentage}
              data={providerBalancesUserAnalytics?.non_verified_users}
              label="Unverified Users"
              loading={loadingProvider}
            />
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <p className="text-[18px] font-dmsans_m">Recent Transactions</p>
          <NewTable
            data={customersTransactionHistory?.transactionHistory ?? []}
            hidePagination={true}
            loading={loadingTransactions}
            total={customersTransactionHistory?.total}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardHome;
