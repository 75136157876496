import Card from 'components/Card/Card';
import React, { useEffect, useMemo, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import SendIcon from 'assets/icons/SendIcon';
import Status from 'components/Status';
import { useVirtualCard } from 'store/modules/virtualCards';
import { groupDataByDate } from 'utils/groupDataByDate';
import Spinner from 'components/Spinner';
import EmptyState from 'components/EmptyState';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import useAdmin from 'hooks/fetch/useAdmin';
import Pagination from 'components/Pagination';

const CardTransactionHistory = () => {
  //Redux Hooks
  const { handleCardHistory } = useAdmin();
  const { cardHistory, cardMetadata, loading, selectedCard } = useVirtualCard();
  //Hooks

  const filterTransactions = useMemo(() => groupDataByDate(cardHistory?.data ?? []), [cardHistory]);
  const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
  const [page, setPage] = useState(1);
  const limit = 10;

  const changePage = (data) => {
    setPage(data.selected + 1);
  };
  useEffect(() => {
    handleCardHistory(selectedCard?.card_sudo_id, page, limit);
  }, [selectedCard?.card_sudo_id, page, limit]);

  return (
    <div>
      <p className=" text-purple font-dmsans_m text-24">Transaction History</p>
      {loading && (
        <div className="mt-10">
          <Spinner />
        </div>
      )}

      {!loading &&
        (filterTransactions?.length > 0 ? (
          <div className="border border-light-smoke rounded-lg p-8 mt-10">
            <div className="grid grid-cols-2 w-full space-x-6 mb-8">
              <Card
                type="custom"
                containerVariant="bg-green-50 p-6 border border-green rounded-xl"
                cardBody={
                  <>
                    <p className="font-dmsans_r text-grey">Inflow</p>
                    <p className="text-dark_blue text-24 font-dmsans_m">
                      {formatCurrency(
                        cardMetadata?.transaction?.inflow ?? 0,
                        selectedCard?.brand?.toLocaleLowerCase() === 'mastercard' ? 'USD' : 'NGN',
                      )}
                    </p>
                  </>
                }
              />
              <Card
                type="custom"
                containerVariant="bg-orange_light p-6 border border-red rounded-xl"
                cardBody={
                  <>
                    <p className="font-dmsans_r text-grey">Outflow</p>
                    <p className="text-dark_blue text-24 font-dmsans_m">
                      {formatCurrency(
                        cardMetadata?.transaction?.outflow ?? 0,
                        selectedCard?.brand?.toLocaleLowerCase() === 'mastercard' ? 'USD' : 'NGN',
                      )}
                    </p>
                  </>
                }
              />
            </div>
            <div className="w-3/4 mx-auto">
              {filterTransactions.map((item) => (
                <div key={item.createdAt}>
                  <p className="border-b pb-1 mt-2 border-smoke text-grey font-dmsans_r">
                    {' '}
                    {item.createdAt === todayDate ? 'Today' : dayjs(item.createdAt).format(' MMM D, YYYY')}
                  </p>
                  {item.items.map((data) => (
                    <div key={data.transaction_id}>
                      <Card
                        containerVariant="mt-[18px] flex justify-between items-center p-4 
                        border-b border-light-smoke
                        "
                        type="custom"
                        cardBody={
                          <>
                            <div className="flex items-center">
                              <div className="bg-purple_transparent hover:bg-white w-10 h-10 flex items-center justify-center rounded-full mr-3">
                                {data?.type?.toLocaleLowerCase() === 'credit' ? (
                                  <BsPlusLg className="text-12 text-green-200" />
                                ) : (
                                  <SendIcon />
                                )}
                              </div>
                              <div className="">
                                {/* <p className="text-grey font-dmsans_m">
                                  To: <span className="text-dark_blue">Kingsley Omin</span>
                                </p>
                                <p className="text-grey text-12 font-dmsans_r mt-0.5">Deposit</p> */}
                                <p className="text-grey font-dmsans_r mt-0.5">{data?.narration}</p>
                              </div>
                            </div>
                            {/* <Status showIcon status="completed" /> */}
                            {data?.type?.toLocaleLowerCase() === 'credit' ? (
                              <p className="text-purple font-dmsans_m text-14">
                                +{formatCurrency(data?.amount, data?.account?.currency)}
                              </p>
                            ) : (
                              <p className="text-danger-red font-dmsans_m text-14">
                                -{formatCurrency(data?.amount, data?.account?.currency)}
                              </p>
                            )}
                          </>
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
              {cardHistory?.data?.length > 0 ? (
                <Pagination
                  changePage={changePage}
                  totalPayload={cardHistory?.totalItems}
                  perPage={limit}
                  currentPage={cardHistory?.currentPage || 0}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <EmptyState />
        ))}
    </div>
  );
};

export default CardTransactionHistory;
