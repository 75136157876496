import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from '../../../hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  virtualCardList: {},
  selectedCard:{},
  cardBalance: {},
  loading: false,
  cardHistory: [],
  cardMetadata: {},
  error: null
}

export const virtualCard = createSlice({
  name: 'virtualCard',
  initialState,
  reducers: {
    setSelectedCard: (state = initialState, action) => {
      state.selectedCard = action.payload;
    },
  },
  extraReducers
})

// Selectors
const selectVirtualCard = (state) => state.virtualCard;

//Reducers and actions
export const {
  setSelectedCard} = virtualCard.actions

//Roles Redux State
export const useVirtualCard = () => useAppSelector(selectVirtualCard);

export default virtualCard.reducer