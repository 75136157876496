import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/axios-instance';
import { admin } from '../../../services/endpoints/admin';

const getVirtualCards = createAsyncThunk('virtualCard/getVirtualCards', async (params) => {
  try {
    const response = await api.get(admin.get_virtual_cards, { params });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});
const getCardDetails = createAsyncThunk('virtualCard/getSingleVirtualCards', async (payload) => {
  try {
    const response = await api.get(`/admin-overview/virtual-card?id=${payload.id}&currency=${payload.currency}`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});
const getCardBalance = createAsyncThunk('virtualCard/getCardBalance', async (id) => {
  try {
    const response = await api.get(`/card/accounts/${id}/balance`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});
const getCardHistory = createAsyncThunk('virtualCard/getCardHistory', async ({ id, page = 1, limit }) => {
  try {
    const response = await api.get(`/card/${id}/transactions?page=${page}&limit=${limit}`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});
export { getVirtualCards, getCardDetails, getCardHistory, getCardBalance };
