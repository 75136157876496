import { getAuditTrail } from 'store/modules/auditTrail/actions';
import { getDashboardAnalytics, getProviderBalancesUserAnalytics } from 'store/modules/dashboard/actions';
import {
  getPaymentHistory,
  getCategoryAnalytics,
  getSinglePayment,
  getGuestPaymentHistory,
} from 'store/modules/payment/actions';
import { getVirtualCards, getCardDetails, getCardBalance, getCardHistory } from 'store/modules/virtualCards/actions';
import {
  getAllRoles,
  getSingleAdmin,
  getSingleRole,
  deactivateAdmin,
  getAllAdmins,
  deleteRole,
  activateAdmin,
} from '../../store/modules/admin/actions';
import useMakeRequest from '../useMakeRequest';
import { getCustomerTransactionHistory, getUserTransactionHistory } from 'store/modules/transfers/actions';

//Handler Functions
export default function useAdmin() {
  const { makeRequest } = useMakeRequest();

  const handleGetAllRole = async (params) => {
    makeRequest({ action: getAllRoles(params), alert: false });
  };

  const handleViewAdmin = async (id, callback) => {
    makeRequest({
      action: getSingleAdmin(id),
      alert: false,
      callback,
    });
  };
  const handleCardBalance = async (id) => {
    makeRequest({
      action: getCardBalance(id),
      alert: false,
    });
  };
  // getCardBalance
  const handleGetAllAdmin = async (params) => {
    makeRequest({
      action: getAllAdmins(params),
      alert: false,
    });
  };

  const handleGetAuditTrail = async (params) => {
    makeRequest({
      action: getAuditTrail(params),
      alert: false,
    });
  };

  const handleSingleRole = async (id) => {
    makeRequest({
      action: getSingleRole(id),
      to: `/settings/edit-role/${id}`,
      alert: false,
    });
  };

  const deactivateadmin = async (email, callback) => {
    makeRequest({
      action: deactivateAdmin({ email: email }),
      callback,
    });
  };

  const activateadmin = async (id, payload, callback) => {
    const data = {
      id,
      payload,
    };
    makeRequest({
      action: activateAdmin(data),
      callback,
    });
  };
  // activateAdmin
  const deleterole = async (role_name, callback) => {
    makeRequest({
      action: deleteRole(role_name),
      callback,
    });
  };

  const handleVirtualCards = async (params) => {
    makeRequest({
      action: getVirtualCards(params),
      alert: false,
    });
  };
  const getSingleVirtualCard = async (id, currency) => {
    const payload = {
      id,
      currency,
    };
    makeRequest({
      action: getCardDetails(payload),
      to: `/virtual-card-management/${id}`,
      alert: false,
    });
  };
  const handleCardHistory = async (id, page, limit) => {
    makeRequest({
      action: getCardHistory({id, page, limit}),
      alert: false,
    });
  };
  const handlePaymentHistory = async (params) => {
    makeRequest({
      action: getPaymentHistory(params),
      alert: false,
    });
  };
  const fetchGuestPaymentHistory = async (params) => {
    makeRequest({
      action: getGuestPaymentHistory(params),
      alert: false,
    });
  };
  const handleViewPayment = async (id, callback) => {
    makeRequest({
      action: getSinglePayment(id),
      alert: false,
      callback,
    });
  };
  const handleCategoryAnalytics = async (params) => {
    makeRequest({
      action: getCategoryAnalytics(params),
      alert: false,
    });
  };
  const handleDashboardAnalytics = async (params) => {
    makeRequest({
      action: getDashboardAnalytics(params),
      alert: false,
    });
  };
  const handleGetUserTransactionHistory = async (params) => {
    makeRequest({
      action: getUserTransactionHistory(params),
      alert: false,
    });
  };
  const handleGetCustomerTransactionHistory = async (params) => {
    makeRequest({
      action: getCustomerTransactionHistory(params),
      alert: false,
    });
  };
  const handleProviderBalancesUserAnalytics = async (params) => {
    makeRequest({
      action: getProviderBalancesUserAnalytics(params),
      alert: false,
    });
  };

  return {
    handleGetAllRole,
    handleCardHistory,
    handleDashboardAnalytics,
    handleViewAdmin,
    handleVirtualCards,
    handleSingleRole,
    deactivateadmin,
    deleterole,
    getSingleVirtualCard,
    handleGetAllAdmin,
    handleGetAuditTrail,
    activateadmin,
    handlePaymentHistory,
    handleCardBalance,
    handleCategoryAnalytics,
    handleViewPayment,
    fetchGuestPaymentHistory,
    handleGetUserTransactionHistory,
    handleProviderBalancesUserAnalytics,
    handleGetCustomerTransactionHistory,
  };
}
